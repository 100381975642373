<template>
    <div class="auth-wrapper auth-v1 px-2">
        <div class="auth-inner py-2">
            <b-overlay :show="loading"
                       :variant="variant"
                       :opacity="opacity"
                       :blur="blur"
                       spinner-variant="primary"
                       spinner-type="grow"
                       spinner-large
                       rounded="sm">
                <b-card class="mb-0">

                    <!-- logo -->
                    <b-link class="brand-logo" to="/">
                        <vuexy-logo />

                    </b-link>

                    <b-card-title class="mb-1">
                        Reset Password 🔒
                    </b-card-title>
                    <!--<b-card-text class="mb-2">
                        Your new password must be different from previously used passwords
                    </b-card-text>-->

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form method="POST"
                                class="auth-reset-password-form mt-2"
                                @submit.prevent="resetPassForm">

                            <!-- password -->
                            <b-form-group label-for="register-password"
                                          label="Password">
                                <validation-provider #default="{ errors }"
                                                     name="Password"
                                                     vid="password"
                                                     rules="required|verify_password">
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="register-password"
                                                      v-model="password"
                                                      class="form-control-merge"
                                                      :type="passwordFieldType"
                                                      :state="errors.length > 0 ? false:null"
                                                      name="register-password"
                                                      placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon :icon="passwordToggleIcon"
                                                          class="cursor-pointer"
                                                          @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-form-group label-for="register-confirmpassword"
                                          label="Confirm Password">
                                <validation-provider #default="{ errors }"
                                                     name="Confirmpassword"
                                                     vid="confirmpassword"
                                                     rules="required|confirmed:password">
                                    <b-input-group class="input-group-merge"
                                                   :class="errors.length > 0 ? 'is-invalid':null">
                                        <b-form-input id="register-confirmpassword"
                                                      v-model="confirmpassword"
                                                      class="form-control-merge"
                                                      :type="passwordFieldType"
                                                      :state="errors.length > 0 ? false:null"
                                                      name="register-confirmpassword"
                                                      placeholder="············" />
                                        <b-input-group-append is-text>
                                            <feather-icon :icon="passwordToggleIcon"
                                                          class="cursor-pointer"
                                                          @click="togglePasswordVisibility" />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <input type="hidden" name="UserId" id="UserId" class="form-control" :value="populateUserId">
                            <input type="hidden" name="Code" id="Code" class="form-control" :value="populateCode">


                            <!-- submit button -->
                            <b-button block
                                      type="submit"
                                      variant="primary">
                                Set New Password
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{name:'auth-login-v1'}">
                            <feather-icon icon="ChevronLeftIcon" /> Back to login
                        </b-link>
                    </p>

                </b-card>
                </b-overlay>
</div>
    </div>

</template>

<script>
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton, BOverlay,
    } from 'bootstrap-vue'
    import useJwt from '@/auth/jwt/useJwt'
    import { required } from '@validations'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import { extend } from 'vee-validate';

    extend('verify_password', {
        message: "Make sure it's at least 7 Characters including UpperCase, LowerCase letter, Number and Special Character.",
        validate: value => {
            var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*.])(?=.{7,})");
            return strongRegex.test(value);
        }
    });

    extend('confirmed', {
        message: "Password and Confirm Password doesn't match",
    });

    export default {
        components: {
            VuexyLogo,
            BCard,
            BButton,
            BCardTitle,
            BCardText,
            BForm,
            BFormGroup,
            BInputGroup,
            BLink,
            BFormInput,
            BInputGroupAppend,
            ValidationProvider,
            ValidationObserver,
            BOverlay
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                loading: false,
                variant: 'light',
                opacity: 0.85,
                blur: '2px',
                userEmail: '',
                password: '',
                confirmpassword: '',
                // validation
                required,

                // Toggle Password
                passwordFieldType: 'password',
            }
        },
        computed: {
            populateUserId() {
                return this.$route.query.userId;
            },
            populateCode() {
                return this.$route.query.code;
            },
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            //password1ToggleIcon() {
            //    /*console.log();*/
            //    return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            //},
            //password2ToggleIcon() {
            //    return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            //},
        },
        methods: {
            togglePassword1Visibility() {
                this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
            },
            togglePassword2Visibility() {
                this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
            },
            resetPassForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.loading = true;
                        /*if (this.password == this.confirmpassword) {*/
                            useJwt.reseetpass({
                                password: this.password,
                                confirmpassword: this.confirmpassword,
                                userid: this.$route.query.userId,
                                code: this.$route.query.code,
                            })
                                .then(response => {
                                    this.loading = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            title: 'Notification',
                                            text: 'Your Password has been successfully updated.',
                                            icon: 'BellIcon',
                                            variant: 'success',
                                        },
                                    })

                                    this.$router.replace('/logout')
                                })
                                .catch(error => {
                                    this.loading = false;
                                    this.$toast({
                                        component: ToastificationContent,
                                        props: {
                                            //title: 'Alert!',
                                            icon: 'BellIcon',
                                            //text: error.response.data.Message,
                                            title: 'Something Went Wrong!',
                                            variant: 'danger',
                                        },
                                    })

                                })
                        //}
                        //else {
                        //    this.loading = false;
                        //    this.$toast({
                        //        component: ToastificationContent,
                        //        props: {
                        //            //title: 'Alert!',
                        //            icon: 'BellIcon',
                        //            //text: error.response.data.Message,
                        //            title: 'Password and confirm password does not match',
                        //            variant: 'danger',
                        //        },
                        //    })
                        //}
                    }
                })
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
    .brand-logo svg {
        height: 70px;
    }
</style>
